import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import humanizeDuration from 'humanize-duration';

dayjs.extend(timezone);
dayjs.extend(utc);

export function formatDuration(duration) {
  return humanizeDuration(duration * 60000, { units: ['h', 'm'] });
}

export function isTodayDate(date, timezone) {
  const dateInTimezone = dayjs(date).tz(timezone).format('YYYY-MM-DD');
  return dayjs(dateInTimezone).isSame(
    dayjs(new Date()).tz(timezone).format('YYYY-MM-DD'),
    'day'
  );
}

export function isExpiredSubscription(date) {
  if (!date) return true;
  const now = dayjs().utc();
  return now.isAfter(dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ssZ'));
}

export function getTimeOptions() {
  const options = [];

  const end = dayjs('1970-01-01T09:30:00.000Z').utc().endOf('day');
  let start = dayjs('1970-01-01T18:00:00.000Z').utc().startOf('day');

  while (start.isBefore(end)) {
    options.push({
      value: start.utc().toISOString(),
      label: dayjs(start).utc().format('h:mma'),
    });
    start = start.add(
      15 + (!start.add(15).isSame(start, 'day') ? -1 : 0),
      'minutes'
    );
  }

  options.push({
    value: end.utc().toISOString(),
    label: dayjs(end).utc().format('h:mma'),
  });

  return options;
}

export function formatBreakTime(date) {
  return dayjs(date).utc().format('h:mma');
}

export function formatBookingDate(date, timezone) {
  return dayjs(date).tz(timezone).format('DD MMM YY');
}

export function formatBookingDateForm(date, timezone) {
  return dayjs(date).tz(timezone).format('MM / DD / YYYY');
}

export function formatBookingTime(date, timezone) {
  return dayjs(date).tz(timezone).format('hh:mm A');
}

export function formatSlots(date) {
  return dayjs(date).format('hh:mm A');
}

export function formatTime(date) {
  return dayjs(date).format('hh:mm A');
}

export function getNow() {
  return dayjs();
}

export function endOfMonth(date) {
  return date.endOf('month').date();
}

export function filterDateIsSameOrAfter(date, filterDate, timezone) {
  return filterDate.isSame(
    dayjs.utc(date).tz(timezone).format('YYYY-MM-DD'),
    'day'
  );
}
