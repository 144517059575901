import { TableRow, TableCell, Skeleton, Stack, Box } from '@mui/material';

export function EmailSkeleton({ ...other }) {
  return (
    <>
      <Skeleton variant="text" width={60} height={20} sx={{ mb: 2 }} />
      <Box {...other}>
        <Skeleton variant="text" width={50} height={20} />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={30}
          sx={{ mb: 2 }}
        />

        <Skeleton variant="text" width={50} height={20} />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 2 }}
        />

        <Skeleton variant="text" width={50} height={20} />
        <Stack sx={{ display: 'flex', flexFlow: 'row' }}>
          <Skeleton variant="text" width={130} height={50} sx={{ mr: 2 }} />
          <Skeleton variant="text" width={130} height={50} sx={{ mr: 2 }} />
          <Skeleton variant="text" width={130} height={50} />
        </Stack>
      </Box>
    </>
  );
}

export function WorkingHoursSkeleton({ ...other }) {
  return (
    <>
      <Skeleton variant="text" width={50} height={20} />
      <Box
        rowGap={1}
        columnGap={1}
        display="grid"
        sx={{ mt: 2, mb: 2 }}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr) 102px',
        }}
        {...other}
      >
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="100%" height={30} />
      </Box>
    </>
  );
}

export function TableSkeleton({ shouldShowImage = false, ...other }) {
  return (
    <TableRow {...other}>
      <TableCell colSpan={12}>
        <Stack spacing={3} direction="row" alignItems="center">
          {shouldShowImage && (
            <Skeleton
              variant="rectangular"
              width={40}
              height={40}
              sx={{ borderRadius: 1, flexShrink: 0 }}
            />
          )}
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width={160} height={20} />
          <Skeleton variant="text" width={160} height={20} />
          <Skeleton variant="text" width={160} height={20} />
          <Skeleton variant="text" width={160} height={20} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
