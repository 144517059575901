import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import axios from '../utils/Axios';
import { setSession } from './utils';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const Types = {
  INITIAL: 'INITIAL',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

const reducer = (state, action) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isNewUser: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isNewUser: action.payload.isNewUser,
      isAuthenticated: true,
      user: null,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      isNewUser: false,
      user: null,
    };
  }
  return state;
};

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('token');
      const userId = localStorage.getItem('user');
      if (accessToken && userId) {
        setSession(accessToken, userId);

        const response = await axios.get('/user');
        const { user } = response.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const verifyGoogleLogin = useCallback(async (code) => {
    const response = await axios.post('/auth/google', {
      code,
    });

    const { token, user, isNewUser, error } = response.data;

    if (error) {
      window.location = '/login?error=auth';
      return;
    }

    setSession(token, user);
    dispatch({
      type: Types.LOGIN,
      payload: {
        isNewUser,
        isAuthenticated: true,
      },
    });
  }, []);

  const loginUserWithPassword = useCallback(async (data) => {
    const response = await axios.post('/auth/login', data);
    const { token, user, isNewUser, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    setSession(token, user);
    dispatch({
      type: Types.LOGIN,
      payload: {
        isNewUser,
        isAuthenticated: true,
      },
    });
  }, []);

  const signUpUser = useCallback(async (data) => {
    const response = await axios.post('/auth/register', data);
    const { token, user, isNewUser, error } = response.data;

    if (error) {
      throw new Error(error);
    }

    setSession(token, user);
    dispatch({
      type: Types.LOGIN,
      payload: {
        isNewUser,
        isAuthenticated: true,
      },
    });
  }, []);

  const logout = useCallback(() => {
    setSession(null, null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isNewUser: state.isNewUser,
      user: state.user,
      setGlobalUser: (user) =>
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        }),
      signUpUser,
      loginUserWithPassword,
      verifyGoogleLogin,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isNewUser,
      state.isInitialized,
      state.user,
      signUpUser,
      loginUserWithPassword,
      verifyGoogleLogin,
      logout,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
