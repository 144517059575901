import { useState } from 'react';
import { Box, Switch, Typography, Stack, Card, Button } from '@mui/material';
import Iconify from '../../../components/iconify';
import axios from '../../../utils/Axios';

const pricingPlans = [
  {
    subscription: 'Standard plan',
    pricing: {
      monthly: {
        id: process.env.REACT_APP_STANDARD_PRICE_MONTHLY,
        price: 24,
      },
      yearly: {
        id: process.env.REACT_APP_STANDARD_PRICE_YEARLY,
        price: 230,
      },
    },
    lists: [
      { text: '10 Services', isAvailable: true },
      { text: '1000 Bookings', isAvailable: true },
      { text: 'All Templates', isAvailable: true },
      { text: 'Reports', isAvailable: true },
      { text: 'Email Notifications', isAvailable: true },
      { text: 'Appointment Statuses', isAvailable: true },
      { text: 'Buffer Between Bookings', isAvailable: true },
      { text: 'Manual Bookings', isAvailable: true },
    ],
    labelAction: 'Start Free Trial',
  },
  {
    subscription: 'Premium plan',
    pricing: {
      monthly: {
        id: process.env.REACT_APP_PREMIUM_PRICE_MONTHLY,
        price: 49,
      },
      yearly: {
        id: process.env.REACT_APP_PREMIUM_PRICE_YEARLY,
        price: 470,
      },
    },
    lists: [
      { text: '50 Services', isAvailable: true },
      { text: '5000 Bookings', isAvailable: true },
      { text: 'All Templates', isAvailable: true },
      { text: 'Reports', isAvailable: true },
      { text: 'Email Notifications', isAvailable: true },
      { text: 'Appointment Statuses', isAvailable: true },
      { text: 'Buffer Between Bookings', isAvailable: true },
      { text: 'Manual Bookings', isAvailable: true },
    ],
    labelAction: 'Start Free Trial',
  },
  {
    subscription: 'Power plan',
    pricing: {
      monthly: {
        id: process.env.REACT_APP_POWER_PRICE_MONTHLY,
        price: 99,
      },
      yearly: {
        id: process.env.REACT_APP_POWER_PRICE_YEARLY,
        price: 950,
      },
    },
    lists: [
      { text: 'Unlimited Services', isAvailable: true },
      { text: 'Unlimited Bookings', isAvailable: true },
      { text: 'All Templates', isAvailable: true },
      { text: 'Reports', isAvailable: true },
      { text: 'Email Notifications', isAvailable: true },
      { text: 'Appointment Statuses', isAvailable: true },
      { text: 'Buffer Between Bookings', isAvailable: true },
      { text: 'Manual Bookings', isAvailable: true },
    ],
    labelAction: 'Start Free Trial',
  },
];

export default function PricingPlans({ user }) {
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');

  return (
    <>
      <Typography variant="h3" align="center" paragraph>
        Choose a plan that suits your needs
      </Typography>

      <Typography align="center" sx={{ color: 'text.secondary' }}>
        Choose your plan and make your first booking form for Webflow!
      </Typography>

      <Box sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="overline">MONTHLY</Typography>

          <Switch
            onChange={() => {
              const isMonthly = selectedPeriod === 'monthly';
              setSelectedPeriod(isMonthly ? 'yearly' : 'monthly');
            }}
          />
          <Typography variant="overline">YEARLY (save 20%)</Typography>
        </Stack>
      </Box>

      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
      >
        {pricingPlans.map((card, index) => (
          <PricingPlanCard
            key={card.subscription}
            user={user}
            card={card}
            index={index}
            selectedPeriod={selectedPeriod}
          />
        ))}
      </Box>
    </>
  );
}

function PricingPlanCard({ card, index, user, sx, selectedPeriod, ...other }) {
  const { subscription, pricing, lists, labelAction } = card;

  const goToStripeCheckoutSession = async () => {
    const price = pricing[selectedPeriod].id;
    console.log(price);
    if (!price) return;

    try {
      const sessionUrl = await axios.post(
        `/users/${user}/subscription_session`,
        { price }
      );
      window.location.href = sessionUrl.data.sessionUrl;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      sx={{
        p: 5,
        boxShadow: (theme) => theme.customShadows.z24,
        ...((index === 0 || index === 2) && {
          boxShadow: 'none',
          bgcolor: 'background.default',
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }),
        ...sx,
      }}
      {...other}
    >
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Stack spacing={1} direction="row" sx={{ my: 2 }}>
        <Typography variant="h2">${pricing[selectedPeriod].price}</Typography>

        <Typography
          component="span"
          sx={{ alignSelf: 'center', color: 'text.secondary' }}
        >
          {selectedPeriod === 'monthly' ? '/mo' : '/yr'}
        </Typography>
      </Stack>

      <Stack component="ul" spacing={2} sx={{ p: 0, my: 5 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body2',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
            }}
          >
            <Iconify
              icon={item.isAvailable ? 'eva:checkmark-fill' : 'eva:close-fill'}
              width={16}
              sx={{ color: item.isAvailable ? 'primary.main' : 'inherit' }}
            />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>

      <Button
        fullWidth
        size="medium"
        variant="contained"
        onClick={goToStripeCheckoutSession}
      >
        {labelAction}
      </Button>
    </Card>
  );
}
