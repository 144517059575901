import { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import timezones from 'timezones-list';
import FormProvider, { RHFSelect } from '../../../components/hook-form';
import WorkingHours from '../../../components/working-hours';
import { getValidConfig } from '../../../utils/schedule';

const BOOKING_STATUS_OPTIONS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'No-Show', value: 'NO_SHOW' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Canceled', value: 'CANCELED' },
];

const BUFFER_OPTIONS = [
  { label: 'No Buffer', value: 0 },
  { label: '5 Mins', value: 5 },
  { label: '10 Mins', value: 10 },
  { label: '15 Mins', value: 15 },
  { label: '20 Mins', value: 20 },
  { label: '30 Mins', value: 30 },
  { label: '45 Mins', value: 45 },
  { label: '60 Mins', value: 60 },
];

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 2.5,
}));

export default function SettingsForm({ config, updateUser }) {
  const [availability, setAvailability] = useState([]);
  const defaultValues = useMemo(
    () => ({ config: getValidConfig(config, { shouldIncludeTimezone: true }) }),
    [config]
  );

  useEffect(() => {
    if (defaultValues.config && defaultValues.config.availability) {
      setAvailability(defaultValues.config.availability);
    }
  }, [defaultValues]);

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    data.config.availability = availability.filter(
      (day) => day.startDate !== 'off' && day.endDate !== 'off'
    );
    await updateUser(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <WorkingHours
              availability={availability}
              setAvailability={setAvailability}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack sx={{ mb: 2 }}>
              <StyledLabel>Default Booking Status:</StyledLabel>
              <RHFSelect native name="config[][bookingStatus]" size="large">
                {BOOKING_STATUS_OPTIONS.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
            <Stack sx={{ mb: 2 }}>
              <StyledLabel>Buffer Time Before Booking:</StyledLabel>
              <RHFSelect native name="config[][beforeBuffer]" size="large">
                {BUFFER_OPTIONS.map((buffer, index) => (
                  <option key={index} value={buffer.value}>
                    {buffer.label}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
            <Stack>
              <StyledLabel>Buffer TIme After Booking:</StyledLabel>
              <RHFSelect native name="config[][afterBuffer]" size="large">
                {BUFFER_OPTIONS.map((buffer, index) => (
                  <option key={index} value={buffer.value}>
                    {buffer.label}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
            <Stack>
              <StyledLabel>Time zone:</StyledLabel>
              <RHFSelect native name="config[][timezone]" size="large">
                {timezones.map(({ tzCode, label }, index) => (
                  <option key={index} value={tzCode}>
                    {label}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              flexGrow={1}
              mt={2}
              mb={2}
            >
              <LoadingButton
                type="submit"
                size="medium"
                variant="contained"
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </FormProvider>
  );
}
