import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Typography, Container } from '@mui/material';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function VerifyLoginPage() {
  const { verifyGoogleLogin, isAuthenticated, isNewUser } = useAuthContext();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get('code');
    const scope = urlSearchParams.get('scope') || '';

    if (code && scope.includes('googleapis')) {
      verifyGoogleLogin(code);
    } else {
      window.location = '/dashboard/app';
    }
  }, []);

  useMemo(async () => {
    if (isAuthenticated && isNewUser) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const state = urlSearchParams.get('state');
      if (state) {
        // Create a new stripe checkout session
        const userId = localStorage.getItem('user');
        const response = await axios.post(
          `users/${userId}/subscription_session`,
          { price: state }
        );
        if (response.data.error) {
          window.location = '/dashboard/app';
        } else {
          window.location = response.data.sessionUrl;
        }
      } else {
        window.location = '/dashboard/app';
      }
    } else if (isAuthenticated) {
      window.location = '/dashboard/app';
    }
  }, [isAuthenticated]);

  return (
    <>
      <Helmet>
        <title>Verify Login | Flow Bookings</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Verifying Login
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Please wait while we veriy your login.
          </Typography>
        </StyledContent>
      </Container>
    </>
  );
}
