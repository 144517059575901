import { forwardRef } from 'react';
import { Box } from '@mui/material';

const Logo = forwardRef(
  ({ width, disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width,
          height: 60,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 721.749999999995 118.93478393554688"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M149.668 89.894V26.2842H191.741V35.553H160.3V52.2733H185.835V61.5422H160.3V89.894H149.668Z"
            fill="#161C24"
          />
          <path
            d="M200.684 89.894V25.5572H210.589V89.894H200.684Z"
            fill="#161C24"
          />
          <path
            d="M243.509 90.621C236.603 90.621 230.939 88.4098 226.517 83.9874C222.094 79.5044 219.883 73.8401 219.883 66.9945C219.883 60.1488 222.094 54.5148 226.517 50.0924C230.939 45.6095 236.603 43.368 243.509 43.368C250.355 43.368 255.989 45.6095 260.412 50.0924C264.895 54.5148 267.136 60.1488 267.136 66.9945C267.136 73.8401 264.895 79.5044 260.412 83.9874C255.989 88.4098 250.355 90.621 243.509 90.621ZM233.605 77.8082C236.149 80.5949 239.451 81.9882 243.509 81.9882C247.568 81.9882 250.84 80.5949 253.324 77.8082C255.868 75.0214 257.14 71.4169 257.14 66.9945C257.14 62.5721 255.868 58.9675 253.324 56.1808C250.84 53.3941 247.568 52.0007 243.509 52.0007C239.451 52.0007 236.149 53.3941 233.605 56.1808C231.121 58.9675 229.879 62.5721 229.879 66.9945C229.879 71.4169 231.121 75.0214 233.605 77.8082Z"
            fill="#161C24"
          />
          <path
            d="M285.324 89.894L271.148 44.0949H281.235L289.867 76.0816L299.772 44.0949H308.95L318.764 76.0816L327.397 44.0949H337.484L323.399 89.894H314.221L304.316 57.9982L294.411 89.894H285.324Z"
            fill="#161C24"
          />
          <path
            d="M345.964 89.894V26.2842H370.136C376.133 26.2842 380.919 27.7381 384.493 30.646C388.128 33.4933 389.946 37.4916 389.946 42.641C389.946 45.4277 389.158 47.9721 387.583 50.2742C386.008 52.5157 384.13 54.1211 381.949 55.0904C385.281 56.302 387.946 58.2708 389.946 60.997C391.945 63.6625 392.944 67.0551 392.944 71.1746C392.944 76.9903 390.975 81.5642 387.038 84.8961C383.161 88.228 377.89 89.894 371.226 89.894H345.964ZM356.596 51.6372H369.5C376.345 51.6372 379.768 48.8808 379.768 43.368C379.768 37.7945 376.315 35.0078 369.409 35.0078H356.596V51.6372ZM356.596 80.5343H369.954C378.375 80.5343 382.585 77.2629 382.585 70.7202C382.585 64.1169 378.375 60.8152 369.954 60.8152H356.596V80.5343Z"
            fill="#161C24"
          />
          <path
            d="M423.3 90.621C416.393 90.621 410.729 88.4098 406.307 83.9874C401.884 79.5044 399.673 73.8401 399.673 66.9945C399.673 60.1488 401.884 54.5148 406.307 50.0924C410.729 45.6095 416.393 43.368 423.3 43.368C430.145 43.368 435.779 45.6095 440.202 50.0924C444.685 54.5148 446.926 60.1488 446.926 66.9945C446.926 73.8401 444.685 79.5044 440.202 83.9874C435.779 88.4098 430.145 90.621 423.3 90.621ZM413.395 77.8082C415.939 80.5949 419.241 81.9882 423.3 81.9882C427.358 81.9882 430.63 80.5949 433.114 77.8082C435.658 75.0214 436.93 71.4169 436.93 66.9945C436.93 62.5721 435.658 58.9675 433.114 56.1808C430.63 53.3941 427.358 52.0007 423.3 52.0007C419.241 52.0007 415.939 53.3941 413.395 56.1808C410.911 58.9675 409.669 62.5721 409.669 66.9945C409.669 71.4169 410.911 75.0214 413.395 77.8082Z"
            fill="#161C24"
          />
          <path
            d="M477.254 90.621C470.348 90.621 464.684 88.4098 460.261 83.9874C455.839 79.5044 453.628 73.8401 453.628 66.9945C453.628 60.1488 455.839 54.5148 460.261 50.0924C464.684 45.6095 470.348 43.368 477.254 43.368C484.1 43.368 489.734 45.6095 494.156 50.0924C498.639 54.5148 500.881 60.1488 500.881 66.9945C500.881 73.8401 498.639 79.5044 494.156 83.9874C489.734 88.4098 484.1 90.621 477.254 90.621ZM467.349 77.8082C469.894 80.5949 473.195 81.9882 477.254 81.9882C481.313 81.9882 484.585 80.5949 487.068 77.8082C489.613 75.0214 490.885 71.4169 490.885 66.9945C490.885 62.5721 489.613 58.9675 487.068 56.1808C484.585 53.3941 481.313 52.0007 477.254 52.0007C473.195 52.0007 469.894 53.3941 467.349 56.1808C464.866 58.9675 463.624 62.5721 463.624 66.9945C463.624 71.4169 464.866 75.0214 467.349 77.8082Z"
            fill="#161C24"
          />
          <path
            d="M541.114 89.894L525.757 68.8119L520.032 74.1733V89.894H510.036V25.5572H520.032V61.3605L538.933 44.0949H551.746L532.754 62.1783L553.2 89.894H541.114Z"
            fill="#161C24"
          />
          <path
            d="M564.816 36.4618C562.998 36.4618 561.484 35.856 560.272 34.6443C559.061 33.3721 558.455 31.8576 558.455 30.1008C558.455 28.2834 559.061 26.7688 560.272 25.5572C561.484 24.285 562.998 23.6489 564.816 23.6489C566.694 23.6489 568.239 24.285 569.45 25.5572C570.722 26.7688 571.359 28.2834 571.359 30.1008C571.359 31.8576 570.722 33.3721 569.45 34.6443C568.239 35.856 566.694 36.4618 564.816 36.4618ZM559.909 89.894V44.0949H569.814V89.894H559.909Z"
            fill="#161C24"
          />
          <path
            d="M581.562 89.894V44.0949H591.467V51.7281C594.98 46.1547 600.008 43.368 606.551 43.368C611.458 43.368 615.426 44.8825 618.455 47.9115C621.545 50.88 623.09 54.8177 623.09 59.7248V89.894H613.185V61.9966C613.185 59.0887 612.337 56.7563 610.64 54.9995C608.944 53.1821 606.672 52.2733 603.825 52.2733C599.948 52.2733 596.919 53.8182 594.738 56.9078C592.557 59.9974 591.467 64.2986 591.467 69.8115V89.894H581.562Z"
            fill="#161C24"
          />
          <path
            d="M667.25 44.0949H677.064V87.3496C677.064 93.226 675.004 97.9816 670.884 101.616C666.826 105.312 661.494 107.16 654.891 107.16C649.499 107.16 644.895 105.857 641.079 103.252C637.262 100.647 634.99 97.2546 634.263 93.0745L643.532 91.5297C643.775 93.7712 644.956 95.5886 647.076 96.982C649.257 98.3753 651.862 99.072 654.891 99.072C658.587 99.072 661.585 98.0724 663.887 96.0733C666.189 94.0741 667.34 91.4994 667.34 88.3492V82.8061C666.189 84.5629 664.372 85.9866 661.888 87.077C659.404 88.1069 656.678 88.6218 653.71 88.6218C647.046 88.6218 641.806 86.5621 637.989 82.4426C634.172 78.2625 632.264 72.7799 632.264 65.9949C632.264 59.2098 634.142 53.7576 637.898 49.6381C641.715 45.458 646.925 43.368 653.528 43.368C656.375 43.368 659.041 43.9132 661.525 45.0037C664.008 46.0941 665.917 47.548 667.25 49.3655V44.0949ZM645.713 76.1725C648.076 78.7169 651.196 79.9891 655.073 79.9891C658.95 79.9891 662.04 78.7169 664.342 76.1725C666.704 73.6281 667.886 70.2355 667.886 65.9949C667.886 61.7542 666.704 58.3617 664.342 55.8173C662.04 53.2729 658.95 52.0007 655.073 52.0007C651.196 52.0007 648.076 53.2729 645.713 55.8173C643.35 58.3617 642.169 61.7542 642.169 65.9949C642.169 70.2355 643.35 73.6281 645.713 76.1725Z"
            fill="#161C24"
          />
          <path
            d="M704.484 90.621C699.335 90.621 695.064 89.3185 691.671 86.7135C688.339 84.048 686.068 80.8675 684.856 77.1721L693.034 73.7189C694.912 79.7164 698.729 82.7152 704.484 82.7152C706.786 82.7152 708.664 82.1397 710.118 80.9886C711.633 79.8376 712.39 78.414 712.39 76.7177C712.39 74.9003 711.511 73.4766 709.755 72.4468C707.998 71.4169 705.847 70.6293 703.303 70.0841C700.819 69.4783 698.335 68.7816 695.851 67.9941C693.367 67.1459 691.247 65.7223 689.49 63.7231C687.733 61.724 686.855 59.119 686.855 55.9082C686.855 52.2733 688.309 49.2746 691.217 46.9119C694.185 44.5493 698.123 43.368 703.03 43.368C707.331 43.368 711.148 44.4281 714.48 46.5485C717.812 48.6082 719.962 51.3041 720.932 54.636L712.753 58.0891C712.208 55.9082 710.997 54.1816 709.119 52.9094C707.301 51.6373 705.302 51.0011 703.121 51.0011C700.94 51.0011 699.214 51.4252 697.941 52.2733C696.669 53.0609 696.033 54.2119 696.033 55.7265C696.033 57.3016 696.911 58.5435 698.668 59.4522C700.425 60.3003 702.545 60.997 705.029 61.5422C707.574 62.0874 710.118 62.7841 712.662 63.6322C715.207 64.4804 717.357 65.9949 719.114 68.1758C720.871 70.3567 721.75 73.1737 721.75 76.6268C721.75 80.6252 720.144 83.9571 716.933 86.6227C713.723 89.2882 709.573 90.621 704.484 90.621Z"
            fill="#161C24"
          />
          <g clipPath="url(#clip0_502_23641)">
            <path
              d="M109.58 16.84V55.824L47.6318 117.888C47.0054 118.516 46.2619 119.013 45.4437 119.352C44.6255 119.691 43.7485 119.866 42.863 119.866H6.74729C5.86103 119.866 4.98334 119.692 4.16442 119.352C3.3455 119.013 2.60142 118.515 1.97475 117.887C1.34807 117.259 0.851096 116.514 0.51225 115.693C0.173403 114.873 -0.000665198 113.994 1.91025e-06 113.106V16.84C-0.000665198 15.952 0.173403 15.0727 0.51225 14.2523C0.851096 13.4318 1.34807 12.6863 1.97475 12.0585C2.60142 11.4306 3.3455 10.9327 4.16442 10.5932C4.98334 10.2537 5.86103 10.0794 6.74729 10.08H102.833C103.719 10.0794 104.597 10.2537 105.416 10.5932C106.234 10.9327 106.979 11.4306 107.605 12.0585C108.232 12.6863 108.729 13.4318 109.068 14.2523C109.407 15.0727 109.581 15.952 109.58 16.84Z"
              fill="url(#paint0_linear_502_23641)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.6582 119.865L67.9803 97.5014V97.5017L109.58 55.8239H109.58H75.2806H56.8749C53.9001 55.8239 51.047 57.0078 48.9435 59.1153C46.84 61.2228 45.6582 64.0812 45.6582 67.0616V119.865Z"
              fill="#46C492"
            />
            <path
              d="M41.0926 5.50561C41.0926 2.97924 39.0484 0.931213 36.5268 0.931213C34.0051 0.931213 31.9609 2.97924 31.9609 5.50561V14.6544C31.9609 17.1808 34.0051 19.2288 36.5268 19.2288C39.0484 19.2288 41.0926 17.1808 41.0926 14.6544V5.50561Z"
              fill="#161C24"
            />
            <path
              d="M77.619 5.50561C77.619 2.97924 75.5748 0.931213 73.0531 0.931213C70.5315 0.931213 68.4873 2.97924 68.4873 5.50561V14.6544C68.4873 17.1808 70.5315 19.2288 73.0531 19.2288C75.5748 19.2288 77.619 17.1808 77.619 14.6544V5.50561Z"
              fill="#161C24"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_502_23641"
              x1="64.2359"
              y1="16.088"
              x2="18.0438"
              y2="112.803"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#61E4A3" />
              <stop offset="1" stopColor="#C2FCCE" />
            </linearGradient>
            <clipPath id="clip0_502_23641">
              <rect
                width="109.58"
                height="118.934"
                fill="white"
                transform="translate(0 0.931213)"
              />
            </clipPath>
          </defs>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return <Box sx={{ display: 'contents' }}>{logo}</Box>;
  }
);

export default Logo;
