import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon, StyledSubItem } from './styles';
import Iconify from '../iconify';
import axios from '../../utils/Axios';

export default function NavSection({
  data = [],
  userId,
  isExpiredSub,
  ...other
}) {
  return (
    <Box {...other}>
      <List disablePadding>
        {data.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            userId={userId}
            isExpiredSub={isExpiredSub}
          />
        ))}
      </List>
    </Box>
  );
}

function NavItem({ item, userId, isExpiredSub }) {
  const { title, path, icon, info, children, stripePortal, isSubOnly } = item;
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  // maybe move this sections layout ..
  const goToStripePortal = async () => {
    const stripePortal = window.open('', '_blank');
    try {
      stripePortal.document.write('Loading flow bookings billing portal...');
      const response = await axios.post(`/users/${userId}/stripe_portal`);
      stripePortal.location.href = response.data.sessionUrl;
    } catch (err) {
      console.log(err);
      stripePortal.close();
    }
  };

  const shouldHideLink = isExpiredSub && isSubOnly;
  const subSection = (
    <>
      <StyledNavItem onClick={toggleMenu}>
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {!openMenu && (
          <Iconify
            width={16}
            icon="eva:arrow-ios-forward-fill"
            sx={{ mr: 1 }}
          />
        )}
        {openMenu && (
          <Iconify
            width={16}
            icon="eva:arrow-ios-downward-fill"
            sx={{ mr: 1 }}
          />
        )}
      </StyledNavItem>
      {openMenu &&
        children.map((child) => (
          <Box>
            <StyledSubItem
              component={RouterLink}
              to={child.path}
              sx={{
                mt: 0.5,
                '&.active': {
                  color: 'rgba(70, 196, 146, 1)',
                  bgcolor: 'rgba(70, 196, 146, 0.08)',
                  fontWeight: 'fontWeightBold',
                },
              }}
            >
              <StyledNavItemIcon>{child.icon}</StyledNavItemIcon>
              <ListItemText disableTypography primary={child.title} />
            </StyledSubItem>
          </Box>
        ))}
      {openMenu && stripePortal ? (
        <Box onClick={goToStripePortal}>
          <StyledSubItem
            sx={{
              mt: 0.5,
              '&.active': {
                color: 'rgba(70, 196, 146, 1)',
                bgcolor: 'rgba(70, 196, 146, 0.08)',
                fontWeight: 'fontWeightBold',
              },
            }}
          >
            <StyledNavItemIcon sx={{ width: 10, height: 10 }}>
              {stripePortal.icon}
            </StyledNavItemIcon>
            <ListItemText disableTypography primary={stripePortal.title} />
          </StyledSubItem>
        </Box>
      ) : null}
    </>
  );

  if (children.length && !shouldHideLink) {
    return subSection;
  }

  return (
    <>
      {shouldHideLink ? null : (
        <StyledNavItem
          component={RouterLink}
          to={path}
          sx={{
            mt: 0.5,
            '&.active': {
              color: 'rgba(70, 196, 146, 1)',
              bgcolor: 'rgba(70, 196, 146, 0.08)',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </StyledNavItem>
      )}
    </>
  );
}
