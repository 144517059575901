import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import Iconify from '../../../components/iconify';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function ShopProductCard({ product, setProduct }) {
  const { name, cover } = product;

  return (
    <Card>
      <Box
        sx={{ pt: '80%', position: 'relative', cursor: 'pointer' }}
        onClick={() => setProduct(product)}
      >
        <StyledProductImg alt={name} src={cover} />
      </Box>

      <Stack
        spacing={2}
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '1.5rem',
        }}
      >
        <Link
          color="inherit"
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => setProduct(product)}
        >
          <Typography variant="subtitle2">{name}</Typography>
        </Link>
        <Box sx={{ mt: '0px !important' }}>
          <a
            href={product.previewLink}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#637381' }}
          >
            <Iconify icon="eva:eye-outline" />
          </a>
        </Box>
      </Stack>
    </Card>
  );
}
